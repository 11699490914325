import React from "react";

import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/solatio-legal-banner.jpg'


const Hero = () => {
    return (

        <section className="hero-slider hero-style-1">
            <div className="slide-inner slide-bg-image" style={{backgroundImage: `url(${hero1})`}}>
                <div className="container">
                    <div data-swiper-parallax="300" className="slide-title">
                        <h2>Crafting Legal Solutions, Brick by Brick</h2>
                    </div>
                    <div data-swiper-parallax="400" className="slide-text">
                        <p>Embark on your legal journey with Solatio Legal, where expertise meets excellence, and your success is our priority.</p>
                    </div>
                    <div className="clearfix"></div>
                    {/*<div data-swiper-parallax="500" className="slide-btns">*/}
                    {/*    <Link to="/contact" className="theme-btn">Book an Appointment</Link>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    )
}

export default Hero;