import React from 'react'
import {Link} from 'react-router-dom'

const Features = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="feature-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="feature-grid">
                            <h3>Analysing the Issue</h3>
                            <p>The process begins with a detailed analysis of the client's legal issue. This involves understanding the facts, identifying relevant laws and regulations, and assessing potential legal implication.</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="feature-grid">
                            <h3>Advice & Guidance</h3>
                            <p>Based on the analysis, our legal team provides personalized advice and guidance to the
                                client. This includes explaining their rights, discussing potential legal strategies,
                                and outlining the available options.</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="feature-grid">
                            <h3>Filing Case</h3>
                            <p>Based on the specific characteristics of the matter, we respond accordingly, which may include initiating proceedings on behalf of the client or mounting a defense against accusations raised by another party.</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="feature-grid">
                            <h3>End-to-End Solution</h3>
                            <p>Our unwavering commitment is deeply rooted in our dedication to providing extensive and unwavering support to our valued clients. Our commitment extends throughout every phase of the intricate legal process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;