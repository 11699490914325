import React from 'react'
import abimg from '../../images/about_us.jpg'
import signature from '../../images/signature.png'
import { Link } from 'react-router-dom';

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about-section section-padding">
            <div className="container">
                <div className="row">

                    <div className="col col-lg-4 col-12">
                        <div className="mid-col">
                            <img src={abimg} alt=""/>
                        </div>
                    </div>
                    <div className="col col-lg-8 col-12">
                        <div className="left-col">
                            <div className="section-title">
                                <div className="icon">
                                    <i className="fi flaticon-home-3"></i>
                                </div>
                                <span>About Solatio Legal</span>
                                <h2>We are the most populer law firm with various law services!</h2>
                                <p>Established in [Year Established], Solatio Legal has been dedicated to offering comprehensive solutions to our clients across various sectors. Our mission is to provide legal assistance under multiple domains, including but not limited to civil law, insurance, banking and finance, legal advice, arbitration, corporate services, and insolvency & bankruptcy matters.</p>
                                <Link onClick={ClickHandler} to="/about" className="more-about">Read More About us <i
                                    className="fi flaticon-next-1"></i></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default About;