import React from 'react'
import ContactFrom from '../ContactFrom';

const Bookingpage = (props) => {
    return (
        <div>
            <section className="contact-section-s3">
                <div className="content-area clearfix">
                    <div className="contact-info-col">
                        <div className="contact-info">
                            <ul>
                                <li>
                                    <i className="fi flaticon-home-3"></i>
                                    <h4>Head Office</h4>
                                    <p>54, Dahs udin sorok, Melborn Austria</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-email"></i>
                                    <h4>Email Address</h4>
                                    <p>demo@example.com</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-support"></i>
                                    <h4>Telephone</h4>
                                    <p>654756175+5474</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-clock"></i>
                                    <h4>Office Hour</h4>
                                    <p>Mon-Sun: 10am – 7pm</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contact-form-col">
                        <div className="section-title-s2">
                            <div className="icon">
                                <i className="fi flaticon-email"></i>
                            </div>
                            <span>Appointment Form</span>
                            <h2>Need Consultancy, Book an Appointment</h2>
                        </div>

                        <div className="contact-form">
                            <ContactFrom />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Bookingpage;