import React from 'react'
import ContactFrom from '../ContactFrom';

const Contactpage = (props) => {
    return (
        <div className="mb-5">
            <section className="contact-section-s3">
                <div className="content-area clearfix">
                    <div className="contact-info-col">
                        <div className="contact-info">
                            <ul>
                                <li>
                                    <i className="fi flaticon-home-3"></i>
                                    <h4>Head Office</h4>
                                    <p>B 1702, The Capital, Science City Rd, Ahmedabad, 380009</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-email"></i>
                                    <h4>Email Address</h4>
                                    <p>demo@example.com</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-support"></i>
                                    <h4>Telephone</h4>
                                    <p>+91-9727177136</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-clock"></i>
                                    <h4>Office Hour</h4>
                                    <p>Mon-Sun: 10am – 7pm</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contact-form-col">
                        <div className="contact-form">
                            <section className="contact-map-section pb-0">
                                <div className="contact-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14682.305934794871!2d72.5094592!3d23.0759835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9dac5bd44017%3A0xd58d295cb9106de1!2sSolatio%20Legal!5e0!3m2!1sen!2sin!4v1710674518696!5m2!1sen!2sin"
                                        width="600" height="450" allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Contactpage;