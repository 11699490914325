import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Bookingpage from "../../components/Bookingpage/Bookingpage";
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';

const BookingPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle={'Book an Appointment'} pagesub={'Contact'}/>
            <Bookingpage/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BookingPage;

