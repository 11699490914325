import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import Logo from '../../images/solatio-legal.png'
import HeaderTopbar from '../HeaderTopbar';


const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [cartActive, setcartState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const { carts } = props;

    return (
        <header id="header" className={`site-header header-style-1 ${props.hclass}`}>
             <HeaderTopbar container={'container'}/>
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo} alt="" /></Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                            <li>
                                <Link onClick={ClickHandler} to="/">Home</Link>
                            </li>
                            <li>
                                <Link onClick={ClickHandler} to="/about">About Us</Link>
                            </li>
                            <li>
                                <Link onClick={ClickHandler} to="/services">Services</Link>
                            </li>
                            <li>
                                <Link onClick={ClickHandler} to="/team">Team</Link>
                            </li>
                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                            <li><Link onClick={ClickHandler} to="/booking">Book an Appointment</Link></li>
                        </ul>
                    </div>

                </div>
            </nav>
        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};


export default connect(mapStateToProps, { removeFromCart })(Header);