import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/solatio-legal.png'
import Practices from '../../api/Practices';



const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <footer className="site-footer">
            {/*<div className="social-newsletter-area">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col col-xs-12">*/}
            {/*                <div className="social-newsletter-content clearfix">*/}
            {/*                    <div className="social-area">*/}
            {/*                        <ul className="clearfix">*/}
            {/*                            <li><Link onClick={ClickHandler} to="/"><i className="ti-facebook"></i></Link></li>*/}
            {/*                            <li><Link onClick={ClickHandler} to="/"><i className="ti-twitter-alt"></i></Link></li>*/}
            {/*                            <li><Link onClick={ClickHandler} to="/"><i className="ti-linkedin"></i></Link></li>*/}
            {/*                            <li><Link onClick={ClickHandler} to="/"><i className="ti-pinterest"></i></Link></li>*/}
            {/*                        </ul>*/}
            {/*                    </div>*/}
            {/*                    <div className="logo-area">*/}

            {/*                    </div>*/}
            {/*                    <div className="newsletter-area">*/}
            {/*                        <div className="inner">*/}
            {/*                            <img src={Logo} alt=""/>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget about-widget">
                                <div className="widget-title">
                                    <h3>About us</h3>
                                </div>
                                <p>Established in [Year Established], Solatio Legal has been dedicated to offering comprehensive solutions to our clients across various sectors.</p>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget contact-widget service-link-widget">
                                <div className="widget-title">
                                    <h3>Our Address</h3>
                                </div>
                                <ul>
                                    <li>B 1702, The Capital, Science City Rd, Ahmedabad, 380009</li>
                                    <li><span>Phone:</span> +91-9727177136</li>
                                    <li><span>Email:</span> demo@example.com</li>
                                    <li><span>Office Time:</span> 10AM- 5PM</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/services">Our services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team">Meet team</Link></li>
                                </ul>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/contact">Privacy Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget line-widget-2">
                                <div className="widget-title">
                                    <h3>Practice Area</h3>
                                </div>
                                <ul>
                                    {Practices.slice(0, 4).map((practice, Sitem) => (
                                        <li key={Sitem}>{practice.sTitle}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="separator"></div>
                        <div className="col col-xs-12">
                            <p className="copyright">Copyright &copy; 2024 Solatio Legal. All rights reserved.</p>
                            <div className="extra-link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/contact">Privacy & Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Terms</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;